// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { Bachelors } from "../components/Bachelors"

import Layout from "../components/layout"
import { Masters } from "../components/Masters"
import SEO from "../components/seo"
import { TempContainer } from "../components/TempContainer"
import { Heading, Text } from "../gazebo"

const EducationPage = () => (
	<Layout>
		<SEO title="Education" />
		<Heading mb={4} fontSize={700} style={{ textAlign: "center" }}>
			Education
		</Heading>
		<TempContainer>
			<Bachelors />
			<Masters />
		</TempContainer>
	</Layout>
)

export default EducationPage
