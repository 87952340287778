import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Heading, Text } from "../gazebo"

const StyledContainer = styled.div`
	margin-bottom: 4rem;
	padding: 2rem 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		& .gatsby-image-wrapper {
			margin-bottom: 24px;
		}
	}
`
const StyledQuickFacts = styled.div`
	padding: 4rem;
	width: 320px;
	background-color: #edbfd4;
	margin-left: 16px;
	border-radius: 16px;
	box-shadow: 0 20px 40px 0 rgb(0 0 67 / 10%);
`
const Bachelors = () => {
	return (
		<Box mb={4}>
			<Heading as="h2" fontSize={500} mb={4}>
				Bachelors
			</Heading>
			<StyledContainer>
				<StaticImage
					src="../images/gordon-blue.png"
					width={500}
					layout="constrained"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="Gordon College logo"
					placeholder="blurred"
				/>
				<StyledQuickFacts>
					<Heading as="h3" fontSize={400} mb={2}>
						Quick Facts
					</Heading>
					<Text>
						<ul style={{ listStyle: "none" }}>
							<li style={{ marginBottom: "1rem" }}>Degree: BA in Social Work &amp; Sociology</li>
							<li style={{ marginBottom: "1rem" }}>GPA: 3.8</li>
							<li style={{ marginBottom: "1rem" }}>Key Courses: Racial Justice</li>
							<li style={{ marginBottom: "1rem" }}>Certifications: LSW in 2018</li>
						</ul>
					</Text>
				</StyledQuickFacts>
			</StyledContainer>
			<Text style={{ margin: "0 auto" }}>
				I graduated Magna Cum Laude from Gordon College in 2016 with a Bachelor of Arts in Social Work and Sociology. Earning this degree
				provided me with a strong theoretical and foundational framework to begin my social work career. This entailed completing courses in
				individual, family, and group therapy; systems of oppression and the role of social work; and statistics and research. While earning
				my degree, I participated in numerous social service opportunities, such as volunteering at the Boys & Girls Club and becoming an
				active member of the Homeless Ministry. I was also a Teaching Assistant for a statistics course.
			</Text>
			<Text pt={1} style={{ margin: "0 auto" }}>
				I became a Licensed Social Worker (LSW) in the state of Massachusetts in 2018.
			</Text>
		</Box>
	)
}

export { Bachelors }
