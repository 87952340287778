import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Heading, Text } from "../gazebo"

const StyledContainer = styled.div`
	margin-bottom: 4rem;
	padding: 2rem 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		& .gatsby-image-wrapper {
			margin-bottom: 24px;
		}
	}
`
const StyledQuickFacts = styled.div`
	padding: 4rem;
	width: 320px;
	background-color: #edbfd4;
	margin-left: 16px;
	border-radius: 16px;
	box-shadow: 0 20px 40px 0 rgb(0 0 67 / 10%);
`

const Masters = () => {
	return (
		<Box mb={4}>
			<Heading as="h2" fontSize={500} mb={4}>
				Masters
			</Heading>
			<StyledContainer>
				<StaticImage
					src="../images/bu-logo.png"
					width={400}
					layout="constrained"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="Boston University logo"
					placeholder="blurred"
				/>
				<StyledQuickFacts>
					<Heading as="h3" fontSize={400} mb={2}>
						Quick Facts
					</Heading>
					<Text>
						<ul style={{ listStyle: "none" }}>
							<li style={{ marginBottom: "1rem" }}>Degree: Masters in Social Work</li>
							<li style={{ marginBottom: "1rem" }}>GPA: 4.0</li>
							<li style={{ marginBottom: "1rem" }}>Key Courses: Racial Justice, Substance Use</li>
							<li style={{ marginBottom: "1rem" }}>Program: Advanced Standing</li>
						</ul>
					</Text>
				</StyledQuickFacts>
			</StyledContainer>
			<Text style={{ margin: "0 auto" }}>
				I began graduate school in the fall of 2019 at the Boston University School of Social Work in the Advanced Standing program. My
				anticipated date of program completion is July 2021.
			</Text>
		</Box>
	)
}

export { Masters }
